require('normalize.css/normalize.css');
require('../styles/index.scss');
import JsonUrl from 'json-url';
import 'json-url/dist/browser/json-url-msgpack';
import 'json-url/dist/browser/json-url-safe64';
import clipboard from 'clipboard-polyfill';
import {phoneValueIsValid, amountValueIsValid, messageValueIsValid} from '../shared/validation';

const lib = JsonUrl('pack');

let phoneValue = "";
let amountValue = "";
let messageValue = "";

let phoneVisited = false;
let amountVisited = false;
let messageVisited = false;

let phoneIsValid = false;
let amountIsValid = false;
let messageIsValid = true;

document.addEventListener("DOMContentLoaded", () => {
    handleInputValidation();
    handleLinkGeneration();
});

let handleInputValidation = () => {
    const inputs = document.getElementsByClassName("input");
    Array.from(inputs).forEach(function(element) {
        element.addEventListener('focusout', inputLostFocus);
        element.addEventListener('input', inputChanged);
    });
};

let inputLostFocus = (e) => {
    let target = e.target.id;
    switch (target) {
        case "phone":
            phoneVisited = true;
            phoneIsValid = validatePhone();
            break;
        case "amount":
            amountVisited = true;
            amountIsValid = validateAmount();
            break;
        case "message":
            messageVisited = true;
            messageIsValid = validateMessage();
    }
    setInputValidState(target);
};

let inputChanged = (e) => {
    let target = e.target.id;
    switch (target) {
        case "phone":
            phoneValue = e.target.value;
            phoneIsValid = validatePhone(phoneValue);
            if (phoneVisited || phoneIsValid) {
                phoneVisited = true;
                setInputValidState(target)
            }

            break;
        case "amount":
            amountValue = e.target.value;
            amountIsValid = validateAmount(amountValue);
            if (amountVisited || amountIsValid) {
                amountVisited = true;
                setInputValidState(target)
            }

            break;
        case "message":
            messageValue = e.target.value;
            messageIsValid = validateMessage(messageValue);
            if (messageVisited || messageIsValid) {
                messageVisited = true;
                setInputValidState(target)
            }
    }
};

let validatePhone = () => {
    return phoneValueIsValid(phoneValue);
};

let validateAmount = () => {
    return amountValueIsValid(amountValue);
};

let validateMessage = () => {
    return messageValueIsValid(messageValue);
};

let setInputValidState = (target) => {
    let input = document.getElementById(target);
    input.nextElementSibling.firstElementChild.style.display = "inline";

    let isValid = false;

    switch (target) {
        case "phone":
            isValid = phoneIsValid;
            break;
        case "amount":
            isValid = amountIsValid;
            break;
        case "message":
            isValid = messageIsValid;
    }

    if (isValid) {
        input.classList.remove('is-danger');
        input.nextElementSibling.firstElementChild.classList.remove("fa-times");
        input.nextElementSibling.firstElementChild.classList.add("fa-check");
    }

    if (!isValid) {
        input.classList.add('is-danger');
        input.nextElementSibling.firstElementChild.classList.remove("fa-check");
        input.nextElementSibling.firstElementChild.classList.add("fa-times");
    }
    setGenerateButtonEnabledState();
    handleCompletedButton(undefined, false);
};

let setGenerateButtonEnabledState = () => {
    let generateButton = document.getElementById("generate-button");
    if (phoneIsValid && amountIsValid && messageIsValid) {
        generateButton.removeAttribute('disabled');
    }
    else {
        generateButton.setAttribute('disabled', '');
    }
};

let handleLinkGeneration = () => {
    const generateButton = document.getElementById('generate-button');
    generateButton.onclick = () => {
        generateLink((compressed) => {
            let url = "https://skicka.money/i/" + compressed;
            clipboard.writeText(url).then(function(){
                handleCompletedButton(true, true);
            }, function(err){
                handleCompletedButton(true, false);
            });

        });
    }
};

let generateLink = (func) => {
    lib.compress([phoneValue, amountValue, messageValue]).then(compressed=> {
        func(compressed);
    });
};


let handleCompletedButton = (sucess, showButton) => {
    const completedButton = document.getElementById("completed-button-field");
    const generateButton = document.getElementById('generate-button-field');

    if (!showButton) {
        completedButton.style.display = "none";
        generateButton.style.display = "inline";
    }

    if (showButton) {
        completedButton.style.display = "inline";
        generateButton.style.display = "none";
    }
    if (showButton && !sucess) {
        const completedButtonText = document.getElementById("completed-button-text");
        completedButtonText.value = "Ett fel uppstod";
    }
};
