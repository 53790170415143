let phoneValueIsValid = (pValue) => {
    let isNumerical = /^\d+$/.test(pValue);
    return pValue.length === 10 && isNumerical;
};

let amountValueIsValid = (aValue) => {
    return /^\d+(\.\d{1,2})?$/.test(aValue);
};

let messageValueIsValid = (mValue) => {
    let isMessageLike = /^[\w\såäöÅÄÖ!?"]+$/.test(mValue);
    return mValue.length <= 50 && (isMessageLike || mValue.length === 0);
};

export {phoneValueIsValid, amountValueIsValid, messageValueIsValid};
